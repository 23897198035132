$c-red: #e9c9c6;
$c-grey: #888;

$f-main: 'Sentinel SSm A', 'Sentinel SSm B';

$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);

.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@mixin bp-s() {
  @media screen and (max-width: 850px) {
    @content;
  }
}

@mixin bp-m() {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin bp-l() {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

.is-hidden-s {
  @include bp-s() {
    display: none;
  }
}
.is-hidden-m {
  display: none;
  @include bp-s() {
    display: block;
  }
}
