@import 'reset';
@import 'helpers';
@import 'header';
@import 'archive';
@import 'article';
@import 'content';

html {
  font-size: 17px;
  overflow-y: scroll;

  @include bp-s() {
    font-size: 15px;
  }
}
body {
  font-family: $f-main;
  color: #000;
  background-color: #fff;
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: $c-grey;

  &:hover {
    color: #000;
  }
}

strong {
  font-weight: 700;
}
em {
  font-style: italic;
}

.container {
  padding: 8rem 2rem 2rem 2rem;

  @include bp-s() {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
  }
}

.l-wrap {
  margin-bottom: 1.5rem;

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
.l-half {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding-right: 1.5rem;

  @include bp-s() {
    width: 100%;
    float: none;
    margin-bottom: 3rem;
    padding-right: 0;
  }
}

.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  padding: 10px 2rem;
  line-height: 1;
  opacity: 0;
  box-sizing: border-box;
  // display: none;

  @include bp-s() {
    padding: 2.5rem 1.5rem;
  }
  &__col {
    display: block;
    font-size: 4.1vw;
    float: left;
    width: 50%;

    @include bp-s() {
      width: 100%;
      font-size: 8vw;
    }
  }
}
