.headline-desktop {
  position: absolute;
  top: 0.7rem;
  left: 0;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;

  h1 {
    width: 15%;
    float: left;
  }
  .subtitle {
    display: block;
    float: left;
  }

  @include bp-s() {
    display: none;
  }
}

.menu-link {
  position: absolute;
  top: 0.75rem;
  left: calc(50% + 0.25rem);

  @include bp-s() {
    position: fixed;
    display: block;
    top: 1rem;
    left: 1.5rem;
    width: 22px;
    height: 13px;
    background-image: url('../images/menu.png');
    background-size: 22px 13px;
    z-index: 6;
    transition: 0.3s transform $easeOutQuint;

    span {
      display: none;
    }
    &.is-active {
      transform: rotate(90deg);
    }
  }
}

.menu-link-backdrop {
  display: none;

  @include bp-s() {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 2.4rem;
    background-color: #fff;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7px;
  background-color: $c-red;
  overflow: hidden;
  z-index: 3;

  @include bp-s() {
    height: 5px;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      color: $c-grey;
    }
  }

  &__col {
    float: left;

    &--one {
      width: 15%;
      padding-right: 0.5rem;
      box-sizing: border-box;

      @include bp-s() {
        padding-top: 1.8rem;
      }
    }
    &--two {
      width: calc(35% + 0.25rem);

      @include bp-s() {
        padding-bottom: 1rem;
      }
    }

    @include bp-s() {
      float: none;
      width: 100%;
    }
  }
  &__content {
    position: absolute;
    top: 0.7rem;
    left: 2rem;
    padding: 0;
    width: calc(100% - 4rem);

    @include bp-s() {
      top: 12px;
      left: 1.5rem;
      width: calc(100% - 3rem);
    }
  }

  &__search-mobile {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('../images/search-mobile.png');
    background-size: 24px 24px;

    @include bp-s() {
      display: block;
    }
  }
  &__search {
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
    border-bottom: 1px solid black;

    input[type='text'] {
      font-family: $f-main;
      background: none;
      width: calc(100% - 25px);
      border: none;
      font-size: 1rem;
      // text-decoration: underline;
      text-align: left;

      &::placeholder {
        color: black;
        font-style: italic;
        transition: 0.4s opacity;
      }
      &:focus {
        outline: none;

        &::placeholder {
          opacity: 0.2;
        }
      }
    }
    button {
      background: none;
      background-image: url('../images/search.png');
      background-size: 100%;
      width: 16px;
      height: 16px;
      border: none;
      position: absolute;
      right: 0;
      bottom: 4px;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
    @include bp-s() {
      display: none;
    }
  }
}

.nav-main {
  li {
    a {
      &.active {
        font-style: italic;
      }
      &:hover {
        // font-style: italic;
        color: $c-grey;
      }
    }
  }
}
