.content {
  margin-bottom: 5rem;
  margin-left: 15%;
  width: 70%;
  max-width: 1400px;

  a {
    text-decoration: none;
    color: $c-grey;

    &:hover {
      color: #000;
    }
  }
  p + h2 {
    margin-top: 3rem;
  }
  p + p {
    margin-top: 1rem;
  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  blockquote {
    margin: 1.5rem 0;
    margin-left: 1.5rem;
    font-style: italic;
  }
  img {
    margin: 0.5rem 0;
  }

  @include bp-m() {
    width: 90%;
    margin-left: 5%;
  }

  @include bp-s() {
    margin-left: 0;
    width: 100%;
    max-width: 500px;

    p + h2 {
      margin-top: 1.5rem;
    }
    img {
      max-width: 100%;
      height: auto !important;
    }
  }
}

.newsletter {
  table {
    width: 100%;

    td {
      padding-top: 1.5rem;
    }
    td:first-child {
      width: 8rem;
    }
  }

  .row {
    display: flex;
  }
  h2 {
    text-transform: none;
  }
  .leftcol {
    width: 8rem;
    display: flex;
    align-items: center;
  }
  .rightcol {
    input {
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px dotted $c-grey !important;
      width: 100%;
      font-family: $f-main;
      font-size: 1rem;
      border-radius: 0;
      padding: 3px 0;

      &:focus {
        outline: none;
        border-bottom: 1px solid black;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    border: none;
    border-bottom: 1px dotted $c-grey;
    width: 100%;
    font-family: $f-main;
    font-size: 1rem;
    border-radius: 0;
    padding: 3px 0;

    &:focus {
      outline: none;
      border-bottom: 1px solid black;
    }
  }
  input[type='submit'],
  button[type='submit'] {
    font-family: $f-main;
    background: none !important;
    border: none;
    border-radius: 0 !important;
    font-size: 1rem;
    color: $c-grey !important;
    cursor: pointer;
    padding: 5px 0 !important;
    min-width: 0 !important;
    margin: 0 !important;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      color: #000 !important;
    }
  }
}
