html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.hidden {
  width: 1px;
  height: 1px;
  position: absolute;
  top: auto;
  left: -10000px;
  overflow: hidden;
}

@media screen and (max-width: 850px) {
  .is-hidden-s {
    display: none;
  }
}

.is-hidden-m {
  display: none;
}

@media screen and (max-width: 850px) {
  .is-hidden-m {
    display: block;
  }
}

.headline-desktop {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  position: absolute;
  top: .7rem;
  left: 0;
}

.headline-desktop h1 {
  width: 15%;
  float: left;
}

.headline-desktop .subtitle {
  float: left;
  display: block;
}

@media screen and (max-width: 850px) {
  .headline-desktop {
    display: none;
  }
}

.menu-link {
  position: absolute;
  top: .75rem;
  left: calc(50% + .25rem);
}

@media screen and (max-width: 850px) {
  .menu-link {
    width: 22px;
    height: 13px;
    z-index: 6;
    background-image: url("menu.96885e61.png");
    background-size: 22px 13px;
    transition: transform .3s cubic-bezier(.23, 1, .32, 1);
    display: block;
    position: fixed;
    top: 1rem;
    left: 1.5rem;
  }

  .menu-link span {
    display: none;
  }

  .menu-link.is-active {
    transform: rotate(90deg);
  }
}

.menu-link-backdrop {
  display: none;
}

@media screen and (max-width: 850px) {
  .menu-link-backdrop {
    z-index: 2;
    width: 100%;
    height: 2.4rem;
    background-color: #fff;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.header {
  width: 100%;
  height: 7px;
  z-index: 3;
  background-color: #e9c9c6;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media screen and (max-width: 850px) {
  .header {
    height: 5px;
  }
}

.header:after {
  content: "";
  clear: both;
  display: block;
}

.header a {
  color: #000;
  text-decoration: none;
}

.header a:hover {
  color: #888;
}

.header__col {
  float: left;
}

.header__col--one {
  width: 15%;
  box-sizing: border-box;
  padding-right: .5rem;
}

@media screen and (max-width: 850px) {
  .header__col--one {
    padding-top: 1.8rem;
  }
}

.header__col--two {
  width: calc(35% + .25rem);
}

@media screen and (max-width: 850px) {
  .header__col--two {
    padding-bottom: 1rem;
  }

  .header__col {
    float: none;
    width: 100%;
  }
}

.header__content {
  width: calc(100% - 4rem);
  padding: 0;
  position: absolute;
  top: .7rem;
  left: 2rem;
}

@media screen and (max-width: 850px) {
  .header__content {
    width: calc(100% - 3rem);
    top: 12px;
    left: 1.5rem;
  }
}

.header__search-mobile {
  width: 24px;
  height: 24px;
  background-image: url("search-mobile.8105fe8b.png");
  background-size: 24px 24px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 850px) {
  .header__search-mobile {
    display: block;
  }
}

.header__search {
  width: 15%;
  border-bottom: 1px solid #000;
  position: absolute;
  top: 0;
  right: 0;
}

.header__search input[type="text"] {
  width: calc(100% - 25px);
  text-align: left;
  background: none;
  border: none;
  font-family: Sentinel SSm A, Sentinel SSm B;
  font-size: 1rem;
}

.header__search input[type="text"]::placeholder {
  color: #000;
  font-style: italic;
  transition: opacity .4s;
}

.header__search input[type="text"]:focus {
  outline: none;
}

.header__search input[type="text"]:focus::placeholder {
  opacity: .2;
}

.header__search button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url("search.9d164713.png") 0 0 / 100%;
  border: none;
  position: absolute;
  bottom: 4px;
  right: 0;
}

.header__search button:hover {
  opacity: .5;
}

@media screen and (max-width: 850px) {
  .header__search {
    display: none;
  }
}

.nav-main li a.active {
  font-style: italic;
}

.nav-main li a:hover {
  color: #888;
}

.archive__tag {
  margin-bottom: 1rem;
}

.archive__table__summary {
  display: none;
  overflow: hidden;
}

.archive__table__summary__subtitle {
  font-style: italic;
}

.archive__table__summary__text {
  margin-bottom: 1.5rem;
}

.archive__table__lang {
  position: absolute;
  top: 0;
  left: 0;
}

.archive a.archive__table__details {
  color: #888;
  font-size: .75rem;
  position: relative;
  top: -1.4rem;
}

.archive a.archive__table__details:hover {
  color: #000;
}

.archive__search {
  width: 17%;
  min-width: 170px;
  margin-bottom: 3rem;
  position: relative;
}

.archive__search input[type="text"] {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  font-family: Sentinel SSm A, Sentinel SSm B;
  font-size: 1rem;
}

.archive__search input[type="text"]:focus {
  outline: none;
}

.archive__search button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url("search.9d164713.png") 0 0 / 100%;
  border: none;
  position: absolute;
  bottom: 4px;
  right: 0;
}

.archive__search button:hover {
  opacity: .5;
}

@media screen and (max-width: 850px) {
  .archive__search {
    width: 100%;
    max-width: 360px;
    min-width: 0;
  }
}

.archive__table-mobile--header {
  padding-top: 2rem;
}

.archive__table-mobile .block {
  border-top: 1px dotted #000;
  margin-bottom: .5rem;
  padding-top: 3px;
  position: relative;
}

.archive__table-mobile .block.is-active {
  border-style: solid;
}

.archive__table-mobile .block__infotoggle {
  font-size: .7rem;
  line-height: 2;
  display: block;
}

.archive__table-mobile .block__meta {
  font-size: .7rem;
}

.archive__table-mobile .block__meta a {
  color: #000;
}

.archive__table-mobile .block__lang {
  margin-top: .7rem;
  font-size: .7rem;
  display: block;
}

.archive__table-mobile .block__details {
  color: #888;
  font-size: .7rem;
  position: absolute;
  top: 3px;
  right: 4.5rem;
}

.archive__table-mobile .block__download {
  color: #888;
  font-size: .7rem;
  position: absolute;
  top: 3px;
  right: 0;
}

.archive__table-mobile .block-hiddeninfo {
  display: none;
}

.archive__table-desktop {
  width: calc(100% + .5rem);
  border-collapse: separate;
  table-layout: fixed;
  margin-left: -.25rem;
}

.archive__table-desktop a {
  color: #000;
}

.archive__table-desktop a:hover {
  color: #888;
}

.archive__table-desktop tr {
  height: 1rem;
}

.archive__table-desktop tr:hover td:before, .archive__table-desktop tr:hover .archive__table-nested tr:first-child td:before, .archive__table-desktop tr.is-active td:before, .archive__table-desktop tr.is-active .archive__table-nested tr:first-child td:before {
  background: #000;
}

.archive__table-desktop td {
  padding: .2rem .5rem 1rem .25rem;
}

.archive__table-desktop td:nth-child(1) {
  width: 10%;
}

.archive__table-desktop td:nth-child(2) {
  width: 40%;
}

.archive__table-desktop td:nth-child(3) {
  width: 20%;
}

.archive__table-desktop td:nth-child(4) {
  width: 10%;
}

.archive__table-desktop td:nth-child(5) {
  width: 20%;
}

.archive__table-desktop td:nth-child(2) {
  padding-top: 0;
  padding-left: 0;
}

.archive__table-desktop td:nth-child(2):before {
  display: none;
}

.archive__table-desktop th, .archive__table-desktop td {
  position: relative;
  overflow: hidden;
}

.archive__table-desktop th:before, .archive__table-desktop td:before {
  content: "";
  width: calc(100% - .5rem);
  height: 1px;
  background: linear-gradient(to right, #fff, #fff 33%, #000 34% 66%, #fff 67%, #fff) 0 0 / 3px 100%;
  display: block;
  position: absolute;
  top: 0;
  left: .25rem;
}

.archive__table-desktop th p, .archive__table-desktop td p {
  max-width: 750px;
}

.archive__table-desktop th {
  text-align: left;
  height: 5rem;
  box-sizing: border-box;
  padding-top: .2rem;
  padding-left: .25rem;
}

.archive__table-desktop th:before {
  background: #000;
}

.archive__table-desktop th.is-active {
  background-image: url("arrow.a91f370d.png");
  background-position: calc(100% - .25rem) 6px;
  background-repeat: no-repeat;
  background-size: 13px 14px;
}

.archive__table-desktop th.is-active.rsortAs {
  background-image: url("arrow-up.4e71a29c.png");
}

.archive__table-desktop th:nth-child(1) {
  width: 10%;
}

.archive__table-desktop th:nth-child(2) {
  width: 5%;
}

.archive__table-desktop th:nth-child(3) {
  width: 35%;
}

.archive__table-desktop th:nth-child(4) {
  width: 20%;
}

.archive__table-desktop th:nth-child(5) {
  width: 10%;
}

.archive__table-desktop th:nth-child(6) {
  width: 20%;
}

.archive__list li:after {
  content: ",";
}

.archive__list li:last-child:after {
  content: "";
}

.archive__table-nested {
  width: 100%;
  table-layout: fixed;
}

.archive__table-nested tr {
  height: auto;
}

.archive__table-nested tr:first-child td:before {
  content: "";
  width: calc(100% - .5rem);
  height: 1px;
  background: linear-gradient(to right, #fff, #fff 33%, #000 34% 66%, #fff 67%, #fff) 0 0 / 3px 100%;
  display: block;
  position: absolute;
  top: 0;
  left: .25rem;
}

.archive__table-nested tr td {
  padding-bottom: 0;
}

.archive__table-nested tr td:before {
  display: none;
}

.archive__table-nested tr td:nth-child(1) {
  width: 12.5%;
}

.archive__table-nested tr td:nth-child(2) {
  width: 87.5%;
  padding-left: .25rem;
}

.author-bio {
  margin-bottom: 3rem;
}

.author-bio p {
  max-width: 700px;
}

.author-bio p + p {
  margin-top: .5rem;
}

@media screen and (max-width: 850px) {
  .author-bio {
    margin-bottom: 1.5rem;
    font-size: .7rem;
  }
}

.categories {
  margin-bottom: 2rem;
  position: relative;
}

.categories__list {
  width: 50%;
}

.categories__list a {
  color: #000;
}

.categories__list a:hover {
  color: #888;
}

.categories__list a.active {
  font-style: italic;
}

.categories__list li {
  margin-bottom: 1rem;
}

@media screen and (max-width: 850px) {
  .categories__list {
    width: 100%;
  }
}

.categories__editors ul, .categories__editors ul li {
  display: inline;
}

.categories__editors ul li:after {
  content: ", ";
}

.categories__editors ul li:last-child:after {
  content: "";
}

.categories__editors a {
  color: #888;
}

.categories__editors a:hover {
  color: #000;
}

.categories--bottom h2 {
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.mobilesort {
  width: calc(100% - 3rem);
  height: 2rem;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid #000;
  display: none;
  position: fixed;
  top: 2.3rem;
  left: 1.5rem;
}

@media screen and (max-width: 850px) {
  .mobilesort {
    display: block;
  }
}

.mobilesort__toggle {
  width: 100%;
  height: 14px;
  background-color: #0000;
  background-image: url("arrow.a91f370d.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 13px 14px;
  display: block;
  position: absolute;
  top: 2px;
  right: 0;
}

.mobilesort__label {
  font-size: .7rem;
  position: absolute;
  top: 5px;
  left: 0;
}

.mobilesort__list {
  margin-left: 4rem;
}

.mobilesort__list li {
  display: none;
}

.mobilesort__list li.is-active {
  display: block;
}

.mobilesort__list li a {
  color: #000;
}

.mobilesort.is-expanded {
  height: auto;
  border-bottom: 1px solid #000;
}

.mobilesort.is-expanded .mobilesort__toggle {
  width: 13px;
  background-position: 0 0;
  top: auto;
  bottom: 5px;
  transform: rotate(180deg);
}

.mobilesort.is-expanded .mobilesort__list li {
  display: block;
}

.mobilesort.is-expanded .mobilesort__list li.is-active {
  font-style: italic;
}

.article {
  max-width: 1200px;
  margin-left: 15%;
}

@media screen and (max-width: 850px) {
  .article {
    margin-left: 0;
  }
}

.article p {
  line-height: 1.4;
}

.article p + p {
  margin-top: 1rem;
}

.article strong {
  font-weight: bold;
}

.article em {
  font-style: italic;
  display: inline;
}

.article .btn-back {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.article .btn-back:before {
  content: "";
  width: 13px;
  height: 14px;
  pointer-events: none;
  background-color: #0000;
  background-image: url("arrow.a91f370d.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 13px 14px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%)rotate(90deg);
}

.article .btn-download {
  color: #000;
}

.article .btn-download:hover {
  font-style: italic;
}

.article__header {
  height: 10rem;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 850px) {
  .article__header {
    height: 7rem;
  }
}

.article__header__rows {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 850px) {
  .article__header__rows {
    padding: 0 1.5rem;
  }
}

.article__header .row {
  width: 100%;
  height: 1.5rem;
  border-top: 1px solid #000;
  align-self: flex-end;
  position: relative;
}

.article__header .row:first-child {
  width: calc(15% - 10px);
  flex-shrink: 0;
  margin-left: 0;
  margin-right: 10px;
}

@media screen and (max-width: 850px) {
  .article__header .row:first-child {
    display: none;
  }
}

.article__authors {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  display: block;
}

.article__authors li {
  display: block;
}

.article__authors li a {
  color: #888;
}

.article__authors li a:hover {
  color: #000;
}

.article__content {
  padding-top: 2rem;
}

.article__content h1 {
  margin-bottom: 6rem;
  font-size: 6rem;
  line-height: .8;
}

.article__content a {
  color: #888;
}

.article__content a:hover {
  color: #000;
}

.article__content .meta {
  max-width: 37rem;
  margin: 3rem 0;
}

.article__content .meta--large {
  font-size: 1.25rem;
}

.article__content .meta--narrowtop {
  margin-top: -1.5rem;
}

.article__content__title {
  max-width: 37rem;
  margin-bottom: 1.5rem;
}

.article__content__title h2 {
  margin-bottom: .5rem;
  font-size: 1.25rem;
}

.article__content__abstract {
  max-width: 37rem;
  margin-bottom: 3rem;
  font-size: 1rem;
}

.article__content__abstract p + p {
  margin-top: .5rem;
}

.article__content__keywords {
  max-width: 37rem;
  margin-bottom: 3rem;
  font-size: 1rem;
}

.article__content__keywords p + p {
  margin-top: .5rem;
}

.article__content__keywords ul li {
  display: inline;
}

.article__content__keywords ul li:after {
  content: ", ";
}

.article__content__keywords ul li:last-child:after {
  content: "";
}

@media screen and (max-width: 850px) {
  .article__content {
    padding-top: 5rem;
  }

  .article__content h1 {
    margin-bottom: 4rem;
    font-size: 4rem;
  }

  .article__content .meta {
    margin-bottom: 1rem;
  }
}

.content {
  width: 70%;
  max-width: 1400px;
  margin-bottom: 5rem;
  margin-left: 15%;
}

.content a {
  color: #888;
  text-decoration: none;
}

.content a:hover {
  color: #000;
}

.content p + h2 {
  margin-top: 3rem;
}

.content p + p {
  margin-top: 1rem;
}

.content h2 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.content blockquote {
  margin: 1.5rem 0 1.5rem 1.5rem;
  font-style: italic;
}

.content img {
  margin: .5rem 0;
}

@media screen and (max-width: 1200px) {
  .content {
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 850px) {
  .content {
    width: 100%;
    max-width: 500px;
    margin-left: 0;
  }

  .content p + h2 {
    margin-top: 1.5rem;
  }

  .content img {
    max-width: 100%;
    height: auto !important;
  }
}

.newsletter table {
  width: 100%;
}

.newsletter table td {
  padding-top: 1.5rem;
}

.newsletter table td:first-child {
  width: 8rem;
}

.newsletter .row {
  display: flex;
}

.newsletter h2 {
  text-transform: none;
}

.newsletter .leftcol {
  width: 8rem;
  align-items: center;
  display: flex;
}

.newsletter .rightcol input {
  width: 100%;
  border-radius: 0;
  padding: 3px 0;
  font-family: Sentinel SSm A, Sentinel SSm B;
  font-size: 1rem;
  border: none !important;
  border-bottom: 1px dotted #888 !important;
  border-radius: 0 !important;
}

.newsletter .rightcol input:focus {
  border-bottom: 1px solid #000;
  outline: none;
}

.newsletter input[type="text"], .newsletter input[type="email"] {
  width: 100%;
  border: none;
  border-bottom: 1px dotted #888;
  border-radius: 0;
  padding: 3px 0;
  font-family: Sentinel SSm A, Sentinel SSm B;
  font-size: 1rem;
}

.newsletter input[type="text"]:focus, .newsletter input[type="email"]:focus {
  border-bottom: 1px solid #000;
  outline: none;
}

.newsletter input[type="submit"], .newsletter button[type="submit"] {
  cursor: pointer;
  border: none;
  font-family: Sentinel SSm A, Sentinel SSm B;
  font-size: 1rem;
  color: #888 !important;
  min-width: 0 !important;
  background: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 5px 0 !important;
}

.newsletter input[type="submit"]:hover, .newsletter input[type="submit"]:focus, .newsletter input[type="submit"]:active, .newsletter button[type="submit"]:hover, .newsletter button[type="submit"]:focus, .newsletter button[type="submit"]:active {
  outline: none;
  color: #000 !important;
}

html {
  font-size: 17px;
  overflow-y: scroll;
}

@media screen and (max-width: 850px) {
  html {
    font-size: 15px;
  }
}

body {
  color: #000;
  background-color: #fff;
  font-family: Sentinel SSm A, Sentinel SSm B;
  line-height: 1.4;
}

a {
  color: #888;
  text-decoration: none;
}

a:hover {
  color: #000;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.container {
  padding: 8rem 2rem 2rem;
}

@media screen and (max-width: 850px) {
  .container {
    padding: 3rem 1.5rem 1.5rem;
  }
}

.l-wrap {
  margin-bottom: 1.5rem;
}

.l-wrap:after {
  content: "";
  clear: both;
  display: block;
}

.l-half {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding-right: 1.5rem;
}

@media screen and (max-width: 850px) {
  .l-half {
    width: 100%;
    float: none;
    margin-bottom: 3rem;
    padding-right: 0;
  }
}

.intro {
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  box-sizing: border-box;
  background-color: #fff;
  padding: 10px 2rem;
  line-height: 1;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 850px) {
  .intro {
    padding: 2.5rem 1.5rem;
  }
}

.intro__col {
  float: left;
  width: 50%;
  font-size: 4.1vw;
  display: block;
}

@media screen and (max-width: 850px) {
  .intro__col {
    width: 100%;
    font-size: 8vw;
  }
}

/*# sourceMappingURL=app.css.map */
