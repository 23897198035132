.archive {
  &__tag {
    margin-bottom: 1rem;
  }
  &__table__summary {
    display: none;
    // padding-bottom: 1rem;
    overflow: hidden;

    &__subtitle {
      font-style: italic;
    }
    &__text {
      margin-bottom: 1.5rem;
    }
  }

  &__table__lang {
    position: absolute;
    top: 0;
    left: 0;
  }

  a.archive__table__details {
    position: relative;
    font-size: 0.75rem;
    color: $c-grey;
    top: -1.4rem;

    &:hover {
      color: #000;
    }
  }

  &__search {
    margin-bottom: 3rem;
    position: relative;
    width: 17%;
    min-width: 170px;

    input[type='text'] {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      font-size: 1rem;
      font-family: $f-main;

      &:focus {
        outline: none;
      }
    }
    button {
      background: none;
      background-image: url('../images/search.png');
      background-size: 100%;
      width: 16px;
      height: 16px;
      border: none;
      position: absolute;
      right: 0;
      bottom: 4px;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    @include bp-s() {
      width: 100%;
      max-width: 360px;
      min-width: 0;
    }
  }

  &__table-mobile {
    &--header {
      padding-top: 2rem;
    }
    .block {
      border-top: 1px dotted #000;
      padding-top: 3px;
      margin-bottom: 0.5rem;
      position: relative;

      &.is-active {
        border-style: solid;
      }

      &__infotoggle {
        display: block;
        // background: #aff;
        line-height: 2;
        font-size: 0.7rem;
      }
      &__meta {
        font-size: 0.7rem;

        a {
          color: #000;
        }
      }
      &__lang {
        display: block;
        font-size: 0.7rem;
        margin-top: 0.7rem;
      }
      &__details {
        font-size: 0.7rem;
        color: $c-grey;
        position: absolute;
        top: 3px;
        right: 4.5rem;
      }
      &__download {
        font-size: 0.7rem;
        color: $c-grey;
        position: absolute;
        top: 3px;
        right: 0;
      }
    }
    .block-hiddeninfo {
      display: none;
    }
  }

  &__table-desktop {
    width: calc(100% + 0.5rem);
    margin-left: -0.25rem;
    border-collapse: separate;
    table-layout: fixed;

    a {
      color: #000;

      &:hover {
        color: $c-grey;
      }
    }
    tr {
      height: 1rem;

      &:hover {
        td {
          &::before {
            background: black;
          }
        }

        .archive__table-nested tr:first-child td::before {
          background: black;
        }
      }

      &.is-active {
        td {
          &::before {
            background: black;
          }
        }

        .archive__table-nested tr:first-child td::before {
          background: black;
        }
      }
    }
    td {
      padding: 0.2rem 0.5rem 1rem 0.25rem;

      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 40%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 20%;
      }

      &:nth-child(2) {
        padding-top: 0;
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
    th,
    td {
      overflow: hidden;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0.25rem;
        width: calc(100% - 0.5rem);
        height: 1px;
        background: linear-gradient(
          to right,
          #fff,
          #fff 33%,
          #000 34%,
          #000 66%,
          #fff 67%,
          #fff
        );
        background-size: 3px 100%;
      }

      p {
        max-width: 750px;
      }
    }
    th {
      text-align: left;
      padding-top: 0.2rem;
      padding-left: 0.25rem;
      height: 5rem;
      box-sizing: border-box;

      &::before {
        background: black;
      }

      &.is-active {
        background-image: url('../images/arrow.png');
        background-repeat: no-repeat;
        background-size: 13px 14px;
        background-position: calc(100% - 0.25rem) 6px;

        &.rsortAs {
          background-image: url('../images/arrow-up.png');
        }
      }

      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 5%;
      }
      &:nth-child(3) {
        width: 35%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 20%;
      }
    }
  }
  &__list {
    li {
      &::after {
        content: ',';
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }

  &__table-nested {
    width: 100%;
    table-layout: fixed;

    tr {
      height: auto;

      &:first-child {
        td {
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0.25rem;
            width: calc(100% - 0.5rem);
            height: 1px;
            background-color: red;
            background: linear-gradient(
              to right,
              #fff,
              #fff 33%,
              #000 34%,
              #000 66%,
              #fff 67%,
              #fff
            );
            background-size: 3px 100%;
          }
        }
      }

      td {
        padding-bottom: 0;

        &::before {
          display: none;
        }

        &:nth-child(1) {
          width: 12.5%;
        }
        &:nth-child(2) {
          width: 87.5%;
          padding-left: 0.25rem;
        }
      }
    }
  }
}

.author-bio {
  margin-bottom: 3rem;

  h1 {
    // margin-bottom: 1rem;
  }
  p {
    max-width: 700px;

    & + p {
      margin-top: 0.5rem;
    }
  }

  @include bp-s() {
    font-size: 0.7rem;
    margin-bottom: 1.5rem;
  }
}

.categories {
  position: relative;
  margin-bottom: 2rem;

  &__list {
    width: 50%;

    a {
      color: #000;

      &:hover {
        color: $c-grey;
      }
      &.active {
        font-style: italic;
      }
    }
    li {
      margin-bottom: 1rem;
    }

    @include bp-s() {
      width: 100%;
    }
  }
  &__editors {
    ul {
      display: inline;

      li {
        display: inline;

        &::after {
          content: ', ';
        }
        &:last-child {
          &::after {
            content: '';
          }
        }
      }
    }
    a {
      color: $c-grey;

      &:hover {
        color: #000;
      }
    }
  }

  &--bottom {
    h2 {
      text-transform: uppercase;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.mobilesort {
  display: none;
  position: fixed;
  top: 2.3rem;
  left: 1.5rem;
  width: calc(100% - 3rem);
  height: 2rem;
  z-index: 2;
  background-color: #fff;
  border-top: 1px solid black;

  @include bp-s() {
    display: block;
  }
  &__toggle {
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    width: 100%;
    height: 14px;
    background-image: url('../images/arrow.png');
    background-size: 13px 14px;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-color: transparent;
  }
  &__label {
    font-size: 0.7rem;
    position: absolute;
    top: 5px;
    left: 0;
  }
  &__list {
    margin-left: 4rem;

    li {
      display: none;

      &.is-active {
        display: block;
      }
      a {
        color: #000;
      }
    }
  }

  &.is-expanded {
    height: auto;
    border-bottom: 1px solid black;

    .mobilesort__toggle {
      top: auto;
      bottom: 5px;
      transform: rotate(180deg);
      background-position: 0 0;
      width: 13px;
    }
    .mobilesort__list li {
      display: block;

      &.is-active {
        font-style: italic;
      }
    }
  }
}
