.article {
  max-width: 1200px;
  margin-left: 15%;

  @include bp-s() {
    margin-left: 0;
  }

  p {
    line-height: 1.4;
  }
  p + p {
    margin-top: 1rem;
  }
  strong {
    font-weight: bold;
  }
  em {
    display: inline;
    font-style: italic;
  }

  .btn-back {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 13px;
      height: 14px;
      background-image: url('../images/arrow.png');
      background-size: 13px 14px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-color: transparent;
      pointer-events: none;
      transform: translateY(-50%) rotate(90deg);
    }
  }
  .btn-download {
    color: black;

    &:hover {
      font-style: italic;
    }
  }
  &__header {
    height: 10rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    position: fixed;
    background: #fff;

    @include bp-s() {
      height: 7rem;
    }

    &__rows {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 0 2rem;
      box-sizing: border-box;
      display: flex;
      // max-width: 1200px;

      @include bp-s() {
        padding: 0 1.5rem;
      }
    }
    .row {
      align-self: flex-end;
      border-top: 1px solid black;
      width: 100%;
      height: 1.5rem;
      position: relative;

      &:first-child {
        width: calc(15% - 10px);
        margin-left: 0;
        margin-right: 10px;
        flex-shrink: 0;

        @include bp-s() {
          display: none;
        }
      }
    }
  }

  &__authors {
    display: block;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;

    li {
      display: block;

      a {
        color: $c-grey;

        &:hover {
          color: #000;
        }
      }
    }
  }
  &__content {
    padding-top: 2rem;

    h1 {
      font-size: 6rem;
      line-height: 0.8;
      margin-bottom: 6rem;
    }
    a {
      color: $c-grey;

      &:hover {
        color: #000;
      }
    }
    .meta {
      margin: 3rem 0;
      max-width: 37rem;

      &--large {
        font-size: 1.25rem;
      }
      &--narrowtop {
        margin-top: -1.5rem;
      }
    }
    &__title {
      max-width: 37rem;
      margin-bottom: 1.5rem;

      h2 {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }
    }
    &__abstract {
      font-size: 1rem;
      margin-bottom: 3rem;
      max-width: 37rem;

      p + p {
        margin-top: 0.5rem;
      }
    }
    &__keywords {
      font-size: 1rem;
      margin-bottom: 3rem;
      max-width: 37rem;

      p + p {
        margin-top: 0.5rem;
      }
      ul {
        li {
          display: inline;

          &::after {
            content: ", ";
          }
          &:last-child {
            &::after {
              content: "";
            }
          }
        }
      }
    }

    @include bp-s() {
      padding-top: 5rem;

      h1 {
        font-size: 4rem;
        margin-bottom: 4rem;
      }
      .meta {
        margin-bottom: 1rem;
      }
    }
  }
}
